import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FormFeedback, Input } from "reactstrap";
import { toast } from "react-toastify";
// Formik validation
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
// css
import styles from "../../setting.module.scss";
//redux
import { useSelector, useDispatch } from "react-redux";
// actions
import { trustContent } from "store/actions";

const TrustedContact = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.CMS);
  //set phone number values
  const [phoneValue, setPhoneValue] = useState("");
  const [country, setCountry] = useState("");

  const { userProfile } = useSelector((state) => state?.Profile);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      trust_first_name: "",
      trust_last_name: "",
      trust_email: "",
      trust_country_code: "",
      trust_phone_number: "",
    },

    validationSchema: Yup.object({
      trust_first_name: Yup.string().required("Please enter your first name"),
      trust_last_name: Yup.string().required("Please enter your last name"),
      trust_email: Yup.string()
        .required("Please enter your email")
        .email("Please enter a valid email address"),
    }),
    onSubmit: (values, { resetForm }) => {
      const phoneNumberWithoutPlus =
        phoneValue && phoneValue.substring(country.length);
      let finalObj = {
        ...values,
        trust_phone_number: phoneNumberWithoutPlus,
        trust_country_code: country && "+" + country,
      };
      const callback = (response) => {
        const { message } = response;
        if (response.status == "success") {
          toast.success(message);
          // resetForm();
          // setPhoneValue("");
          navigate("/setting");
        }
      };
      dispatch(trustContent(finalObj, callback));
    },
  });

  useEffect(() => {
    const filedKeysArr = [
      "trust_country_code",
      "trust_email",
      "trust_first_name",
      "trust_last_name",
      "trust_phone_number",
    ];

    if (userProfile) {
      filedKeysArr.forEach((field) => {
        validation?.setFieldValue(field, userProfile[field]).then((res) => {
          validation?.setFieldError(field, "");
        });
      });
      setPhoneValue((p) =>
        userProfile?.trust_country_code
          ? userProfile?.trust_country_code + userProfile?.trust_phone_number
          : ""
      );
      setCountry(
        userProfile?.trust_country_code ? userProfile?.trust_country_code : ""
      );
    }
  }, [userProfile]);

  const handlePhoneChange = (value, country) => {
    setPhoneValue(value);
    setCountry(country?.dialCode);
  };
  console.log(validation?.errors, "trust errors");
  return (
    <>
      <div className="py-2">
        <h6 className="m-0  fw-sbold">Contact Details</h6>
        <p className="m-0 text-muted">Update your Contact details here</p>
      </div>
      <div className="py-2">
        <Form
          className="setting_profile"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            validation.setTouched({
              trust_first_name: true,
              trust_last_name: true,
              trust_email: true,
              trust_phone_number: true,
              trust_country_code: true,
            });
            return false;
          }}
        >
          <Row>
            <Col lg="12" className="">
              <Row>
                <Col lg="4" className="my-2">
                  <label className="m-0  fw-sbold">Name</label>
                </Col>
                <Col lg="4" sm="6" className="my-2">
                  <Input
                    type="text"
                    placeholder="First Name"
                    className={`${styles.formControl} form-control box bg-transparent  rounded`}
                    name="trust_first_name"
                    value={validation.values.trust_first_name || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.trust_first_name &&
                      validation.errors.trust_first_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.trust_first_name &&
                  validation.errors.trust_first_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.trust_first_name}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col lg="4" sm="6" className="my-2">
                  <Input
                    type="text"
                    placeholder="Last Name"
                    name="trust_last_name"
                    className={`${styles.formControl} form-control box bg-transparent  rounded`}
                    value={validation.values.trust_last_name || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.trust_last_name &&
                      validation.errors.trust_last_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.trust_last_name &&
                  validation.errors.trust_last_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.trust_last_name}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg="12" className="">
              <Row>
                <Col lg="4" className="my-2">
                  <label className="m-0  fw-sbold">Email Address</label>
                </Col>
                <Col lg="8" className="my-2">
                  <Input
                    type="email"
                    placeholder="georgia.young@example.com"
                    name="trust_email"
                    className={`${styles.formControl} form-control box bg-transparent  rounded`}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.trust_email || ""}
                    invalid={
                      validation.touched.trust_email &&
                      validation.errors.trust_email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.trust_email &&
                  validation.errors.trust_email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.trust_email}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg="12" className="">
              <Row>
                <Col lg="4" className="my-2">
                  <label className="m-0  fw-sbold">Phone Number</label>
                </Col>
                <Col lg="8" className="my-2">
                  <PhoneInput
                    enableAreaCodes={true}
                    name="trust_phone_number"
                    placeholder="Phone Number"
                    value={phoneValue || ""}
                    onChange={handlePhoneChange}
                    onBlur={validation.handleBlur}
                    maxLength={15}
                    className={`${phoneValue == "" ? "is-invalid" : ""}`}
                    invalid={
                      validation.touched.trust_phone_number &&
                      validation.errors.trust_phone_number
                        ? true
                        : false
                    }
                  />
                  {validation.touched.trust_phone_number && phoneValue == "" ? (
                    <FormFeedback type="invalid">
                      Please enter your phone number
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="btnWrpper text-end py-3">
            <Button
              className="d-inline-flex align-items-center justify-content-center commonBtn rounded"
              type="submit"
            >
              {loading ? "Loading…" : "Update"}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default TrustedContact;
