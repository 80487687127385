import { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";

const SocketContext = createContext();

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  const userId = localStorage?.getItem("customerid");

  useEffect(() => {
    const socketInstance = io(API_BASE_URL, {
      transports: ["websocket"],
      secure: true,
    });

    // When a connection is made
    socketInstance?.on("connect", (data) => {
      socketInstance?.emit(
        "customersocket",
        { customerId: JSON.parse(userId) || "" },
        (response) => {
          console.log("response from customersocket:", response);
        }
      );

      setSocket((p) => socketInstance);
    });

    return () => {
      socket?.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
