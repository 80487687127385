import React, { useState } from "react";
import transactionImg from "../../../../Assets/images/transaction.png";
import { useSelector } from "react-redux";
import moment from "moment";
import { formatStandardNumberWithMaxDecimal } from "helpers/helper";
import HistoryDetailModal from "./HistoryDetailsModel";

export const formatDateTime = (
  utcDateTime,
  timezone = "America/New_York",
  key
) => {
  // Define format strings based on the required key
  const formats = {
    date: "DD MMMM YYYY",
    date2: "DD-MM-YY",
    time: "hh:mm A",
    dateNTime: "DD MMMM YYYY hh:mm A",
  };

  // Validate if a correct format key is provided
  if (!formats[key]) {
    throw new Error(
      'Invalid format key provided. Use "date", "time", or "dateNTime".'
    );
  }

  // Perform the formatting
  return moment.utc(utcDateTime).tz(timezone).format(formats[key]);
};

const HistoryList = ({ data, loader }) => {
  const [showHistoryModel, setShowHistoryModel] = useState({
    show: false,
    details: null,
  });

  const { userProfile } = useSelector((state) => state?.Profile);

  let userTimezone = userProfile?.timezone;

  return (
    <>
      <HistoryDetailModal
        show={showHistoryModel}
        setShow={setShowHistoryModel}
        type={"history"}
        // stockView={}
      />
      <div className="py-2">
        <ul className="list-unstyled ps-0 mb-0">
          {data?.map((item) => {
            let bankId = item?.bankId;
            let itemType = item?.type;
            let itemStatus = item?.status;
            return (
              <li className="py-2">
                <div
                  className="box rounded cardCstm"
                  style={{ fontSize: 10, lineHeight: "12px" }}
                  onClick={() => {
                    setShowHistoryModel((p) => ({
                      show: true,
                      details: item,
                    }));
                  }}
                >
                  <div className=" p-2 top d-flex align-items-center justify-content-between gap-10">
                    <div className="left d-flex align-items-center gap-10">
                      <div className="imgWrp d-flex align-items-center justify-content-center flex-shrink-0  border border-secondary rounded">
                        <img
                          src={transactionImg}
                          alt=""
                          className="img-fluid"
                          style={{
                            // background:
                            //   itemType === "withdrawl"
                            //     ? itemStatus === "rejected"
                            //       ? "yellow"
                            //       : "green"
                            //     : itemType === "incoming"
                            //     ? itemStatus === "rejected"
                            //       ? "yellow"
                            //       : "green"
                            //     : "green",
                            background:
                              itemStatus === "rejected"
                                ? "red"
                                : itemStatus === "pending"
                                ? "yellow"
                                : "green",

                            height: 40,
                            width: 40,
                          }}
                        />
                      </div>
                      <div className="content ">
                        <h6 className="m-0" style={{ color: "#82C3FF" }}>
                          {item?.type === "incoming"
                            ? `Deposit  ${bankId ? "Bank Transfer" : ""}`
                            : item?.type === "withdrawl"
                            ? `Withdraw  ${bankId ? "Bank Transfer" : ""}`
                            : ""}
                        </h6>
                        <p className="m-0  fw-bold pt-1 m-0">
                          {formatDateTime(
                            item?.createdAt,
                            userTimezone,
                            "date"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="right text-end">
                      <h6
                        className="m-0  fw-sbold"
                        style={{
                          color: Number(item.amount) > 0 ? "green" : "red",
                        }}
                      >
                        {/* {` ${
                          item.amount > 0 ? "+" : "-"
                        } $${formatStandardNumberWithMaxDecimal(
                          item?.amount ?? 0,
                          2
                        )}`} */}

                        {`$${formatStandardNumberWithMaxDecimal(
                          item?.amount ?? 0,
                          3
                        )}`}
                      </h6>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        {data?.length === 0 && !loader && (
          <div
            className="box rounded cardCstm my-3"
            style={{ fontSize: 10, lineHeight: "12px" }}
          >
            <div className="py-5 text-center">
              <h6 className="m-0  fw-sbold">No Data available</h6>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HistoryList;
