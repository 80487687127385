import ReactPagination from "components/Common/ReactPagination";
import { AFFILIATE_TRANSACTION_LIST } from "helpers/backend_helper";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import {
  dateFormat,
  formatStandardNumberWithMinNMaxDecimal,
  itemsPerPage,
} from "helpers/helper";
import CopyToClip from "components/Common/CopyToClip";

const Transactions = ({ data, getData, loading }) => {
  const [field, setFields] = useState({
    startDate: "",
    endDate: "",
  });

  const [filter, setFilter] = useState({
    page: 1,
    limit: itemsPerPage,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });

  const handleChangePicker = async (selectedDate, name) => {
    console.log(selectedDate, "input date");
    // Perform actions based on the selected date and field name
    let dateFormat = selectedDate
      ? moment(selectedDate).format("DD/MM/YYYY")
      : "";
    console.log(dateFormat, "input date 1");

    setFields((prev) => ({
      ...prev,
      [name]: dateFormat,
    }));
    // Rest of your code...
  };

  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    // window.scrollTo(0, 0);
  };

  useEffect(() => {
    getData(filter, field);
  }, [filter.page, field.endDate, field.startDate]);

  const onClearFiler = () => {
    setFields((p) => ({
      startDate: "",
      endDate: "",
    }));
  };

  console.log(
    moment(field.startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
    "date data"
  );

  return (
    <div className="py-2">
      <p className="m-0 fw-sbold">Select your date Range</p>
      <Row>
        <Col lg="3" md="4" sm="6" className="my-2">
          {/* <Datetime
            // key={key}
            value={field.startDate || ""}
            inputProps={{
              placeholder: "dd/mm/yyyy",
              readOnly: true,
              className: "form-control box",
            }}
            name="startDate"
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            isValidDate={(current) => {
              return current.isSameOrBefore(moment(), "day");
            }}
            onChange={(selectedDate) =>
              handleChangePicker(selectedDate, "startDate")
            }
            closeOnSelect={true}
          /> */}
          <input
            type="date"
            style={{
              color: "white",
            }}
            placeholder="DD/MM/YYYY"
            value={
              field?.startDate
                ? moment(field.startDate, "DD/MM/YYYY").format("YYYY-MM-DD")
                : ""
            }
            onChange={(selectedDate) =>
              handleChangePicker(selectedDate.target.value, "startDate")
            }
            className="rounded outline-none px-2"
          />
        </Col>
        <Col lg="3" md="4" sm="6" className="my-2">
          <input
            type="date"
            style={{
              color: "white",
            }}
            value={
              field?.startDate
                ? moment(field.endDate, "DD/MM/YYYY").format("YYYY-MM-DD")
                : ""
            }
            onChange={(selectedDate) =>
              handleChangePicker(selectedDate.target.value, "endDate")
            }
            className="rounded outline-none px-2"
          />
          {/* <Datetime
            // key={key}
            value={field.endDate || ""}
            inputProps={{
              placeholder: "dd/mm/yyyy",
              readOnly: true,
              className: "form-control box",
            }}
            name="endDate"
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            isValidDate={(current) => {
              // Parse the current date string in DD/MM/YYYY format to a moment object
              const currentDate = moment(current, "DD/MM/YYYY", true);
              // Parse the start date string in DD/MM/YYYY format to a moment object
              const startDate = moment(field?.startDate, "DD/MM/YYYY", true);
              // Check if the current date is the same as or after the start date
              return (
                currentDate.isValid() &&
                startDate.isValid() &&
                currentDate.isSameOrAfter(startDate, "day")
              );
            }}
            onChange={(selectedDate) =>
              handleChangePicker(selectedDate, "endDate")
            }
            closeOnSelect={true}
          /> */}
        </Col>
        <Col lg="3" className="d-flex align-items-center">
          <Button
            style={{ backgroundColor: "red", borderColor: "red" }}
            className="d-flex align-items-center justify-content-center fw-sbold"
            onClick={onClearFiler}
          >
            Clear
          </Button>
        </Col>
      </Row>

      <div
        style={{
          minHeight: 300,
        }}
      >
        <Col lg="12" className="my-2">
          <div className="box p-0 tableCard">
            <div className="table-responsive commonTable">
              <table className="table m-0">
                {/* <thead>
                  <tr>
                    <th className="  fw-sbold">Name</th>
                    <th className="  fw-sbold">Withdrawal Amount</th>
                    <th className="  fw-sbold"></th>
                  </tr>
                </thead> */}
                <tbody>
                  {data?.data?.length > 0 &&
                    data?.data?.map((list, i) => {
                      return (
                        <tr key={i}>
                          <td className="border-0 py-3 ">
                            <div className="d-flex align-items-center gap-10">
                              <img
                                src={list?.imgUrlDark}
                                className="stock-list-size"
                              />
                              <div className="content">
                                <Row className="d-flex gap-10">
                                  <Col>
                                    <p className=" m-0 fw-bold text-muted  mb-0">
                                      Account{" "}
                                      <CopyToClip
                                        value={
                                          list?.userDetails?.accountData
                                            ?.account_number
                                        }
                                      />
                                    </p>
                                    <p className=" m-0 fw-bold   mb-0">
                                      {
                                        list?.userDetails?.accountData
                                          ?.account_number
                                      }
                                    </p>
                                  </Col>

                                  <Col>
                                    <p className=" m-0 fw-bold text-muted  mb-0">
                                      Trade Id{" "}
                                      <CopyToClip value={list?.tread_id} />
                                    </p>
                                    <p className=" m-0 fw-bold   mb-0">
                                      {list?.tread_id}
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </td>
                          <td className="border-0 py-3 ">
                            <p className="mb-0 fw-sbold text-muted">
                              ${" "}
                              {list?.treadAmount
                                ? parseFloat(list?.treadAmount).toFixed(2)
                                : "0"}
                            </p>

                            <p className="mb-0 fw-sbold  text-warning ">
                              + ${" "}
                              {
                                list?.amount
                              }
                            </p>

                            <p className="mb-0 fw-sbold text-muted">
                              {dateFormat(list.createdAt)}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {data?.data?.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={data?.totalcount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default Transactions;
