import React from "react";
import { Link, useNavigate } from "react-router-dom";

const PopularStocks = ({ popularStockList }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex align-items-center justify-content-between gap-10 cardHead mb-2">
        <h6 className="m-0  fw-sbold">Popular Stocks</h6>
        <Link to="/popular-stock-detail" className="themeClr">
          See All
        </Link>
      </div>
      <div
        className="box position-relative py-2"
        style={{ fontSize: 10, lineHeight: "20px" }}
      >
        <ul className="list-unstyled ps-0 mb-0">
          {popularStockList?.length > 0 &&
            popularStockList?.slice(0, 5).map((list, i) => {
              return (
                <li 
                  className="py-3 px-3 d-flex align-items-center justify-content-between gap-10 cursorPointer"
                  style={{ borderBottom: "1px solid var(--darkLightGray)" }}
                  key={i}
                  onClick={() =>
                    navigate("/stock-complete-details/" + list?.symbol)
                  }
                >
                  <div className="left d-flex align-items-center">
                    <span className="">
                      {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 34 34"
                  fill="none"
                >
                  <circle cx="17" cy="17" r="17" fill="#00DCFA" />
                  <path
                    d="M20.1808 6.72089C16.5372 6.72089 13.4419 9.52368 13.0519 13.1308C9.44488 13.5207 6.64209 16.616 6.64209 20.2596C6.64209 24.2201 9.85921 27.4372 13.8197 27.4372C17.4633 27.4372 20.5707 24.6344 20.9485 21.0273C24.5556 20.6374 27.3584 17.5421 27.3584 13.8985C27.3584 9.93801 24.1413 6.72089 20.1808 6.72089V6.72089ZM25.6767 14.7028C25.3233 17.1034 23.3979 19.0288 20.9973 19.3822V14.7028H25.6767ZM8.32376 19.4553C8.67716 17.0425 10.6026 15.1171 13.0032 14.7637V19.4431H8.32376V19.4553ZM19.3156 21.0761C18.9134 23.7936 16.5859 25.8164 13.8197 25.8164C11.0534 25.8164 8.7259 23.7936 8.32376 21.0761H19.3156ZM19.3765 14.7028V19.4431H14.624V14.7028H19.3765ZM25.6767 13.082H14.6849C15.087 10.3645 17.4146 8.34163 20.1808 8.34163C22.947 8.34163 25.2746 10.3645 25.6767 13.082Z"
                    fill="white"
                  />
                </svg> */}
                      <img
                        src={list?.imgUrl}
                        className="class-img-icon-popular"
                      />
                    </span>
                    <div className="content">
                      <h6 className=" fw-sbold m-0">{list?.symbol}</h6>
                      <p className="m-0  fw-light">{list?.shortName}</p>
                    </div>
                  </div>
                  <div className="right text-end">
                    <h6 className=" fw-sbold m-0">
                      ${list?.price ? parseFloat(list?.price).toFixed(2) : "0"}
                    </h6>
                    <p className="m-0 themeGreen fw-sbold">
                      <span
                        style={{
                          color: list?.change?.toString().includes("-")
                            ? "red"
                            : "green",
                        }}
                      >
                        {list?.change
                          ? `${list.change > 0 ? "+" : ""}${parseFloat(
                              list.change
                            ).toFixed(2)}`
                          : "0.00"}
                      </span>
                      <span
                        style={{
                          color: list?.changePercentage
                            ?.toString()
                            .includes("-")
                            ? "red"
                            : "green",
                        }}
                      >
                        (
                        {list?.changePercentage
                          ? `${
                              list.changePercentage > 0 ? "+" : ""
                            }${parseFloat(list.changePercentage).toFixed(2)}%`
                          : "0.00"}
                        )
                      </span>{" "}
                      <span
                        className="icn ms-1"
                        style={{
                          color: list?.change?.toString().includes("-")
                            ? "red"
                            : "green",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 12 7"
                          fill="none"
                        >
                          <path
                            d="M10.6512 6.76367L1.34122 6.76367C1.17643 6.76364 1.01534 6.7123 0.878337 6.61615C0.741328 6.52 0.634545 6.38336 0.571487 6.2235C0.508429 6.06363 0.491926 5.88773 0.524066 5.71802C0.556206 5.54831 0.635545 5.39242 0.752052 5.27005L5.40705 0.382297C5.56332 0.21826 5.77525 0.126108 5.99622 0.126108C6.21719 0.126108 6.42911 0.21826 6.58539 0.382297L11.2404 5.27005C11.3569 5.39242 11.4362 5.54831 11.4684 5.71802C11.5005 5.88773 11.484 6.06363 11.421 6.22349C11.3579 6.38336 11.2511 6.52 11.1141 6.61615C10.9771 6.7123 10.816 6.76363 10.6512 6.76367Z"
                            fill="#00B833"
                          />
                        </svg>
                      </span>
                    </p>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};

export default PopularStocks;
