import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import Transactions from "./AffiliateTAbs/Transactions";
import ClientsTab from "./AffiliateTAbs/ClientsTab";
import {
  AFFILIATE_TRANSACTION_LIST,
  AFFILIATE_CLIENT_LIST,
} from "helpers/backend_helper";
import { formatStandardNumberWithMinNMaxDecimal } from "helpers/helper";

const AffiliateArea = () => {
  const [filter, setFilter] = useState({
    page: 1,
    limit: 1000,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });
  const [data, setData] = useState();
  const [clientData, setClientData] = useState();
  console.log(clientData?.totalWalletBalanced, data, "clientData");

  const [totalUsers, setTotalUsers] = useState(0);
  const [transactionLoading, setTransactionLoading] = useState();

  const getData = async (filter, field) => {
    setTransactionLoading(true);
    try {
      const response = await AFFILIATE_TRANSACTION_LIST({
        ...filter,
        startDate: field?.startDate ?? "",
        endDate: field?.endDate ?? "",
      });
      if (response.status === "failure") {
        setTransactionLoading(false);
        return toast.error(response?.message);
      }
      setTransactionLoading(false);
      setData(response);
    } catch (error) {
      setTransactionLoading(false);
      toast.error("something went wrong");
    }
  };
  const getClientData = async () => {
    try {
      const response = await AFFILIATE_CLIENT_LIST({
        ...filter,
      });
      if (response.status === "failure") {
        setLoading(false);
        return toast.error(response?.message);
      }
      setClientData(response);
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  useEffect(() => {
    getClientData();
  }, []);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-4">
          <h5 className="m-0 currentColor fw-sbold mb-1">OverView</h5>
          <p>Total Paid Commissions Overtime</p>
          <p>
            ${" "}
            {formatStandardNumberWithMinNMaxDecimal(
              data?.countData?.totalApproved
            )}
          </p>
        </div>

        <div className="col-lg-4">
          <p className="m-0 currentColor mb-1">Pending commissions</p>$
          {formatStandardNumberWithMinNMaxDecimal(
            data?.countData?.totalPending
          )}
        </div>

        <div className="col-lg-4">
          <p>Total Trade Count</p>
          <p>
            {" "}
            {formatStandardNumberWithMinNMaxDecimal(
              data?.pageData?.totalTread
            )}
          </p>
        </div>

        <div className="col-lg-4">
          <p className="m-0 currentColor mb-1">Total Trade Sum</p>
          {formatStandardNumberWithMinNMaxDecimal(
           data?.pageData?.totalSumOfTread
          )}
        </div>

        <div className="col-lg-4">
          <p className="mb-0 d-flex align-items-center gap-1">Total Clients</p>
          <p className="mb-0">{totalUsers}</p>
        </div>
      </div>

      <div className="py-2">
        <Tab.Container id="left-tabs-example" defaultActiveKey="transactions">
          <Nav
            variant="pills"
            className={` underlineTab gap-10`}
            style={{
              borderBottom: "1px solid var(--darkLightGray)",
            }}
          >
            <Nav.Item className="px-1">
              <Nav.Link
                className={` bg-transparent border-0 p-0 position-relative pb-2
                        fw-sbold`}
                eventKey="transactions"
                // onClick={() => handleTabClick("Pending")}
              >
                Transaction
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="px-1">
              <Nav.Link
                className={` bg-transparent border-0 p-0 position-relative pb-2 
                        fw-sbold`}
                eventKey="clients"
                // onClick={() => handleTabClick("Historical")}
              >
                Client
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="transactions">
              <Transactions
                data={data}
                getData={getData}
                loading={transactionLoading}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="clients">
              <ClientsTab setTotalUsers={setTotalUsers} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default AffiliateArea;
