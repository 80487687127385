import axios from "axios";
import authHeader from "./jwt-token-access/auth-token-header";

//apply base url for axios
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_URL = API_BASE_URL + process.env.REACT_APP_API_BASE_URL_EXTENSION;
const API_VERSION = process.env.REACT_APP_API_VERSION;
export const SOCKET_URL = process.env.REACT_APP_API_SOCKET_URL;

const axiosApi = axios.create({
  baseURL: API_URL + API_VERSION,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const updateToken = (data) => {
  const token = localStorage.getItem("token");
  const customerid = localStorage.getItem("customerid");

  axiosApi.defaults.headers.common["token"] = token;
  axiosApi.defaults.headers.common["customerid"] = `${JSON.parse(customerid)}`;
};

const obj = JSON.parse(localStorage.getItem("authUser"));
updateToken(obj ? obj?.token : null);

const LogoutUser = () => {
  const email = localStorage.getItem("rememberedEmail");
  const password = localStorage.getItem("rememberedPassword");
  const theme = localStorage.getItem("isDarkMode");
  localStorage.clear();
  localStorage.setItem("rememberedPassword", password);
  localStorage.setItem("rememberedEmail", email);
  localStorage.setItem("isDarkMode", theme);
  window.location.replace("/");
};
export async function get(url, config) {
  if (!config) {
    config = authHeader();
    console.log("Request Config:", config);
  }

  try {
    const response = await axiosApi.get(url, { ...config });
    console.log("API Response:", response.data);

    if (response.data.status === "failure") {
      if (response.data.isInvalidToken) {
        return LogoutUser();
      } else if (response.data.message === "Not Authorized!") {
        // console.log("her");
        LogoutUser();
        return { status: "failure" };
      }
    }

    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
}

export async function post(url, data, config) {
  if (!config) {
    config = authHeader();
  }

  return axiosApi.post(url, data, { ...config }).then((response) => {
    if (response.data.status === "failure") {
      if (response.data.isInvalidToken) {
        return LogoutUser();
      } else if (
        response.data.message ===
        "Access denied. you didn't have permission to access this end point"
      ) {
        LogoutUser();
        return { status: "failure" };
      }
    }

    return response.data;
  });
}

export async function put(url, data, config) {
  if (!config) {
    config = authHeader();
  }

  return axiosApi.put(url, { ...data }, { ...config }).then((response) => {
    if (response.data.status === "failure") {
      if (response.data.isInvalidToken) {
        return LogoutUser();
      } else if (
        response.data.message ===
        "Access denied. you didn't have permission to access this end point"
      ) {
        LogoutUser();
        return { status: "failure" };
      }
    }

    return response.data;
  });
}

export async function del(url, config) {
  if (!config) {
    config = authHeader();
  }

  return await axiosApi.delete(url, { ...config }).then((response) => {
    if (response.data.status === "failure") {
      if (response.data.isInvalidToken) {
        return LogoutUser();
      } else if (
        response.data.message ===
        "Access denied. you didn't have permission to access this end point"
      ) {
        LogoutUser();
        return { status: "failure" };
      }
    }
    return response.data;
  });
}
